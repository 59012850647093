import { useState } from 'react'
import { useTranslations } from 'next-intl'
import { Input } from '@/app/components/Input'
import { SearchBackground } from '@/app/icons/SearchBackground'

type TextSearchInputProps = {
  onSearch: (searchTerm: string) => void
  defaultValue?: string
  noIcon?: boolean
}

export const TextSearchInput = ({
  onSearch,
  defaultValue,
  noIcon = false,
  ...props
}: TextSearchInputProps) => {
  const [term, setTerm] = useState(defaultValue)
  const t = useTranslations('header')

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = e.currentTarget.value.trim()
      if (value) {
        onSearch(value)
      }
    }
  }

  const handleIconClick = () => {
    if (term && term.trim()) {
      onSearch(term.trim())
    }
  }

  return (
    <Input
      {...props}
      type="text"
      value={term}
      onChange={(e) => setTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={t('text_search_placeholder')}
      className="focus:border-mydra-purple focus:ring-0"
      {...(!noIcon && {
        icon: SearchBackground,
        iconPosition: 'end',
        iconSize: 36,
        onIconClick: handleIconClick,
      })}
    />
  )
}
