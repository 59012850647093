import React from 'react'

export interface IconProps {
  width?: number
  height?: number
  className?: string
}

export const SearchBackground: React.FC<IconProps> = ({
  width = 40,
  height = 40,
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40.0003" height="40" rx="8" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1667 10C16.7355 10 14.4039 10.9658 12.6849 12.6849C10.9658 14.4039 10 16.7355 10 19.1667C10 21.5978 10.9658 23.9294 12.6849 25.6485C14.4039 27.3676 16.7355 28.3333 19.1667 28.3333C21.1063 28.3333 22.9825 27.7186 24.5349 26.5971L27.9378 30L30.0002 27.9377L26.5973 24.5347C27.7187 22.9823 28.3333 21.1062 28.3333 19.1667C28.3333 16.7355 27.3676 14.4039 25.6485 12.6849C23.9294 10.9658 21.5978 10 19.1667 10ZM14.158 14.158C15.4864 12.8296 17.2881 12.0833 19.1667 12.0833C21.0453 12.0833 22.8469 12.8296 24.1753 14.158C25.5038 15.4864 26.25 17.288 26.25 19.1667C26.25 21.0452 25.5038 22.8469 24.1753 24.1753C22.8469 25.5037 21.0453 26.25 19.1667 26.25C17.2881 26.25 15.4864 25.5037 14.158 24.1753C12.8296 22.8469 12.0833 21.0452 12.0833 19.1667C12.0833 17.288 12.8296 15.4864 14.158 14.158Z"
        fill="white"
      />
    </svg>
  )
}
