import React, { SVGProps } from 'react'
import Link from 'next/link'
import { MessageKeys, useTranslations } from 'next-intl'
import { SecondaryButton } from '../SecondaryButton'
import { Dropdown } from 'flowbite-react'
import LogoutIcon from '@/app/icons/LogoutIcon/LogoutIcon'

type Route = {
  title: string
  route: string
  icon?: React.FC<SVGProps<SVGSVGElement>>
  target?: string
}

type MenuSection = {
  title: string
  routes: Route[]
}

type HeaderMenuProps = {
  sections: MenuSection[]
  onLogout: () => void
  onClose?: () => void
  userInitials?: string
  userName?: string
  variant?: 'mobile' | 'dropdown'
}

export const HeaderMenu = ({
  sections,
  onLogout,
  onClose,
  userInitials,
  userName,
  variant = 'mobile',
}: HeaderMenuProps) => {
  const t = useTranslations('header')

  if (variant === 'dropdown') {
    return (
      <Dropdown
        label=""
        dismissOnClick={false}
        placement="bottom-end"
        className="mt-1 !-left-6 min-w-[240px]"
        renderTrigger={() => (
          <span className="items-center justify-center hidden w-12 h-12 text-lg font-bold text-white rounded-full cursor-pointer bg-mydra-purple md:flex">
            {userInitials}
          </span>
        )}
      >
        <Dropdown.Header>
          <div className="flex items-center gap-3">
            <span className="flex items-center justify-center w-10 h-10 text-sm font-bold text-white rounded-full cursor-pointer bg-mydra-purple">
              {userInitials}
            </span>
            <span className="text-sm font-bold">{userName}</span>
          </div>
        </Dropdown.Header>
        {sections.map(({ routes }) =>
          routes.map(({ title, route, icon: Icon, target }, index) => (
            <Link
              href={route}
              className="focus:outline-none"
              key={index}
              target={target}
            >
              <Dropdown.Item
                icon={Icon}
                className="hover:bg-mydra-purple-light focus:bg-mydra-purple-light text-gray-light"
              >
                {t(title as MessageKeys<any, any>)}
              </Dropdown.Item>
            </Link>
          ))
        )}
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={onLogout}
          icon={LogoutIcon}
          className="hover:bg-mydra-purple-light focus:bg-mydra-purple-light text-gray-light"
        >
          {t('logout')}
        </Dropdown.Item>
      </Dropdown>
    )
  }

  return (
    <div className="container flex flex-col gap-8 p-8">
      {sections.map(
        ({ title, routes }) =>
          routes?.length > 0 && (
            <React.Fragment key={title}>
              <div className="flex flex-col gap-4">
                <div className="text-sm font-semibold uppercase text-gray-light">
                  {t(title)}
                </div>
                {routes.map(({ title, route, icon: Icon, target }) => (
                  <Link
                    href={route}
                    className="focus:outline-none"
                    key={route}
                    target={target}
                    onClick={onClose}
                  >
                    <div className="flex items-center gap-4 text-base text-black">
                      {Icon && <Icon width={20} />}
                      <span>{t(title)}</span>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="h-[1px] border-b border-lines"></div>
            </React.Fragment>
          )
      )}
      <SecondaryButton onClick={onLogout}>{t('logout')}</SecondaryButton>
    </div>
  )
}
