import React, { forwardRef } from 'react'
import { ErrorMessage } from '@/app/components/ErrorMessage'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  icon?: any
  iconPosition?: 'start' | 'end'
  iconSize?: number
  errorMessage?: string
  register?: unknown
  onIconClick?: () => void
}

export type Ref = HTMLInputElement

export const Input = forwardRef<Ref, InputProps>(
  (
    {
      type,
      icon,
      iconPosition = 'start',
      iconSize = 16,
      label,
      id,
      errorMessage,
      placeholder,
      ...props
    },
    ref
  ) => {
    const Icon = icon
    return (
      <div className={`flex flex-col ${props.className || ''}`}>
        {label && (
          <label className="pb-2 font-sans text-mydra-black" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="relative w-full">
          <input
            {...props}
            style={{
              paddingLeft:
                icon && iconPosition === 'start'
                  ? `${iconSize + 16}px`
                  : '12px',
              paddingRight:
                icon && iconPosition === 'end' ? `${iconSize + 16}px` : '12px',
            }}
            className={`w-full h-12 px-5 border-[1px] ${
              props.disabled ? 'bg-mydra-gray cursor-not-allowed' : ''
            } border-gray-200 rounded-lg leading-8 text-mydra-black text-sm ${
              props.className || ''
            } ${errorMessage && `border-red-500`}`}
            type={type}
            ref={ref}
            id={id}
            placeholder={placeholder}
          />
          {icon && (
            <div
              className={`absolute top-1/2 -translate-y-1/2 ${
                iconPosition === 'start' ? 'left-2' : 'right-2'
              } ${props.onIconClick ? 'cursor-pointer hover:opacity-80 transition-opacity duration-200' : ''}`}
              onClick={props.onIconClick}
              tabIndex={props.onIconClick ? 0 : -1}
              role={props.onIconClick ? 'button' : undefined}
            >
              <Icon width={iconSize} height={iconSize} />
            </div>
          )}
        </div>

        <ErrorMessage message={errorMessage} className="mt-4 text-left" />
      </div>
    )
  }
)

Input.displayName = 'Input'
