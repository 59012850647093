import React from 'react'

export const ErrorMessage = ({ message, ...props }) => {
  if (!message) return <></>
  return (
    <span {...props} className={`input-error-message ${props.className ?? ''}`}>
      {message}
    </span>
  )
}
