import React, { createContext, useContext, useState } from 'react'

type SearchInputContextType = {
  isLargeSearchVisible: boolean
  setIsLargeSearchVisible: (visible: boolean) => void
}

const SearchInputContext = createContext<SearchInputContextType | undefined>(
  undefined
)

export const SearchInputProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isLargeSearchVisible, setIsLargeSearchVisible] = useState(true)

  return (
    <SearchInputContext.Provider
      value={{ isLargeSearchVisible, setIsLargeSearchVisible }}
    >
      {children}
    </SearchInputContext.Provider>
  )
}

export const useSearchInputContext = () => {
  const context = useContext(SearchInputContext)
  if (!context) {
    return { isLargeSearchVisible: false, setIsLargeSearchVisible: () => {} }
  }
  return context
}
